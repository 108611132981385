import HimalayaPointsCard from './HimalayaPointsCard';
const EngagementCard = ({ flags, rewardPoints }) => {
  const { isHimalayaWellness } = flags;
  if (isHimalayaWellness) {
    return <HimalayaPointsCard rewards={rewardPoints} />;
  }
  return <></>;
};

export default EngagementCard;
