import React from 'react';
import { useTheme } from 'styled-components';
import { SocialBottom } from '../../social/Social';
import { NykaaOnline } from '../../social/NykaaSocial';
import { BestSellerOnline } from '../../social/BestSellerSocial.jsx';
import { MarcaOnline } from '../../social/MarcaSocial';
import { PoweredByBranding } from '../../PoweredByBranding';
import NewFooter from '../../footer/NewFooter';
import { BillExplicit } from '../../BillExplicit';
import { downloadAppLink } from '../data';
import { ShilpiSocial } from 'components/social/ShilpiSocial';
import { EemosSocial } from 'components/social/EemosSocial';
import { SocialGlobal } from 'components/social/SocialGlobal';
import RxWellSocial from 'components/social/RxwellPharmacy';
import { ConnplexSocial } from 'components/social/Connplex';
import { WowMomosSocial } from 'components/social/WowMomosSocial';
import { HimalayaWellnessSocial } from 'components/social/HimalayaWellnessSocial';
import { NyCinemaSocial } from 'components/social/NycinemaSocial';
import { KayaSocial } from 'components/social/KayaSocial';
import { ManyavarSocial } from 'components/social/ManyavarSocial';
import { RzpPosSocial } from 'components/social/RzpPosSocial';
import LoyaltyCard from '../../LoyaltyCard';
import { SocialGroupIcons } from 'components/social/SocialGroupIcons';
import { WowFranciseSocial } from 'components/social/WowFranchiseSocial';

const SocialBottomRow = (props) => {
  const {
    flags,
    brandLogo,
    billImageUrl,
    consent,
    vendorId,
    user,
    parsedData,
    handleDownloadBillClick,
    billUid,
    handleDownloadSpecificHTML,
    brandId,
    companyId,
  } = props;
  const {
    isCentroGrande,
    isNykaaEcom,
    showSocial,
    isMarcaEcomm,
    isBestSeller,
    isWowFranchise,
    isWowKulfi,
    isWowChina,
    showDownloadAppText,
    hideFooterButton,
    showMetaText,
    isDecathlonIndiaEcom,
    isCinepolisTicket,
    isCinepolisGroup,
    isBK,
    isMiraj,
    isDeluxCaterers,
    isCarnival,
    isMomaiya,
    isEemosSalon,
    isConnplex,
    isShilpiBill,
    isGadchiroli,
    isCityOfPatna,
    isRxWellPharmacy,
    isPrasads,
    isSPRVLCrocodile,
    isSPRVLAngelAndRocket,
    isRawMango,
    isHalt,
    isZudio,
    isShowLoyaltyCard,
    isBodyBuildingIndia,
    isHdfChargeSlip,
    isMahavirStore,
    isDemoWestside,
    isNyCinemas,
    isRzpPos,
    isSangamSelection,
    isDecathIndPlay,
    isSuperK,
    isKaya,
    isCinepolisIndonesia,
    isBewakoof,
    isWestside,
    isManyavar,
    isTwamev,
    isMebaz,
    isMohey,
    isManyavarMohey,
    isManyavarbrand,
    isHouseofFett,
    isGoColors,
    isRzpFtx25,
    isZimson,
    isSugarCosmetics,
    isMiArcus,
    isWowMomos,
    isWowChicken,
    isTFSCompany,
    isSammm,
    isHimalayaWellness,
  } = flags;
  const theme = useTheme();
  const getDownloadLink = () => {
    if (isDecathlonIndiaEcom) return downloadAppLink.decathlonIndiaEcom;
    else if (isNykaaEcom) return downloadAppLink.nykaaIndiaEcom + `?billUid=${billUid}`;
    else if (isMarcaEcomm) return parsedData?.transactionalData?.deliveryStatusUrl;
    else if (isCinepolisGroup) return downloadAppLink?.cinepolisGroup;
    else if (isHalt) return downloadAppLink?.halt;
    else if (isBodyBuildingIndia) return downloadAppLink?.bodyBuilding;
    else if (isWestside) return downloadAppLink?.westside;
    else if (isDemoWestside) return '#';
  };

  const getBgColor = () => {
    if (isMiraj || isCarnival) {
      return '#000';
    } else if (isConnplex) {
      return '#231F20';
    } else if (isPrasads) {
      return '##29156D';
    } else if (isRawMango) {
      return '#FEFBFA';
    } else if (isRzpPos) {
      return '#0C2651';
    } else if (isCinepolisTicket) {
      return 'transparent';
    } else if (isManyavar) {
      return '#FEECDB';
    } else {
      return theme.color.aquaHaze;
    }
  };

  return (
    <>
      {isNykaaEcom && <NykaaOnline />}
      {showSocial && <SocialBottom isCentroGrande={isCentroGrande} />}
      {isMarcaEcomm && <MarcaOnline />}
      {isBestSeller && <BestSellerOnline />}
      {isMiraj && !isConnplex && <SocialGlobal template="miraj" />}
      {isCarnival && <SocialGlobal template="carnival" />}
      {isConnplex && <ConnplexSocial />}
      {isWowMomos && <WowMomosSocial />}
      {isWowFranchise && !isWowMomos && (
        <WowFranciseSocial
          template={isWowChicken ? 'wowChicken' : isWowKulfi ? 'wowKulfi' : 'wowChina'}
        />
      )}
      {isHimalayaWellness && <HimalayaWellnessSocial />}
      {isNyCinemas && <NyCinemaSocial />}
      {isKaya && <KayaSocial />}
      {isPrasads && <SocialGlobal template="prasads" />}
      {isMomaiya && <SocialGlobal template="momaiya" />}
      {isBodyBuildingIndia && <SocialGlobal template="bodyBuildingIndia" />}
      {isMahavirStore && <SocialGlobal template="mahavirHomeStore" />}
      {isSangamSelection && <SocialGlobal template="sangamSelection" />}
      {isSuperK && <SocialGlobal template="superk" />}
      {isGoColors && <SocialGlobal template="goColors" />}
      {isSammm && <SocialGlobal template="sammm" />}
      {isHouseofFett && <SocialGlobal template="houseofFett" />}
      {isManyavarbrand && <ManyavarSocial />}
      {isMohey && <ManyavarSocial />}{' '}
      {/*for now rendering manyavar template as default for manyavar brands*/}
      {isMebaz && <ManyavarSocial />}{' '}
      {/*for now rendering manyavar template as default for manyavar brands*/}
      {isManyavarMohey && <ManyavarSocial />}
      {isTwamev && <ManyavarSocial />}{' '}
      {/*for now rendering manyavar template as default for manyavar brands*/}
      {isSuperK && <SocialGroupIcons template="superk" />}
      {isCinepolisIndonesia && <SocialGlobal template="cinepolisIndonesiaFnb" />}
      {isZimson && <SocialGlobal template="zimson" />}
      {isBewakoof && <SocialGlobal template="bewakoof" />}
      {isSugarCosmetics && <SocialGlobal template="sugarcosmetics" />}
      {isTFSCompany && <SocialGlobal template="tfs" />}
      {isRzpPos && <RzpPosSocial />}
      {isSPRVLCrocodile && <SocialGlobal template="sprvlCrocodile" />}
      {isSPRVLAngelAndRocket && <SocialGlobal template="sprvlAngelAndRocket" />}
      {isMiArcus && <SocialGlobal template="miArcus" />}
      {isShilpiBill && <ShilpiSocial />}
      {isEemosSalon && <EemosSocial />}
      {isRxWellPharmacy && <RxWellSocial />}
      {isZudio && (
        <LoyaltyCard
          brandLogo={brandLogo}
          user={user}
          handleDownloadSpecificHTML={handleDownloadSpecificHTML}
        />
      )}
      {isDeluxCaterers && (
        <p style={{ fontSize: '12px', textAlign: 'center' }}>
          Feedback -{' '}
          <a
            href="mailto:feedback.india@charcoalconcepts.com?subject=Customer Feedback"
            target="_blank">
            feedback.india@charcoalconcepts.com
          </a>
        </p>
      )}
      {(!isCinepolisGroup || isCinepolisTicket) && !isBK && !isRzpFtx25 && (
        <PoweredByBranding
          isWhiteLogo={(isMiraj && !isConnplex) || isCinepolisTicket || isRzpPos}
          fontSize={isMiraj || isCarnival || isConnplex || isPrasads ? '18px' : '16px'}
          textColor={
            (isMiraj && !isConnplex) || isCarnival || isPrasads || isCinepolisTicket || isRzpPos
              ? '#FFF'
              : theme.color.mineShaft
          }
          bgColor={isConnplex ? 'transparant' : getBgColor()}
          fontWeight={(isCarnival || isConnplex) && 600}
          fontFamily={isRawMango ? 'DIN' : isCinepolisTicket ? 'Montserrat' : null}
          isMiraj={isMiraj}
          isConnplex={isConnplex}
          isNyCinemas={isNyCinemas}
          brandId={brandId}
          companyId={companyId}
          isManyavar={isManyavar}
        />
      )}
      {!isMiraj && !isCarnival && !isConnplex && !isPrasads && !isDecathIndPlay && (
        <NewFooter
          isWowChina={isWowChina}
          isWowKulfi={isWowKulfi}
          isWowFranchise={isWowFranchise}
          isBestSeller={isBestSeller}
          showDownloadAppText={showDownloadAppText}
          makeLogoBig={isCarnival || isDemoWestside || isManyavar}
          brandLogo={brandLogo}
          billUrl={billImageUrl}
          downloadAppLink={getDownloadLink}
          btnColor={isNykaaEcom ? theme.color.nykaa : isCinepolisTicket ? '#7F30C4' : ''}
          customText={isMarcaEcomm && 'Track Order'}
          showMetaText={showMetaText}
          hideFooterButton={hideFooterButton}
          handleDownloadBillClick={handleDownloadBillClick}
          isBK={isBK}
          isGadchiroli={isGadchiroli}
          isCityOfPatna={isCityOfPatna}
          isCinepolisGroup={isCinepolisGroup}
          isCinepolisTicket={isCinepolisTicket}
          isHdfChargeSlip={isHdfChargeSlip}
          isNyCinemas={isNyCinemas}
          isBewakoof={isBewakoof}
          isMahavirStore={isMahavirStore}
          isManyavar={isManyavar}
          isGoColors={isGoColors}
          isRzpFtx25={isRzpFtx25}
          isSammm={isSammm}
        />
      )}
      {consent?.isPresent ? (
        <BillExplicit user={user} message={consent?.message} vendorId={vendorId} />
      ) : null}
    </>
  );
};

export default SocialBottomRow;
