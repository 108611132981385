import React from 'react';

import {
  TwitterIconofHimalayaWellness,
  InstagramIconofHimalayaWellness,
  FacebookIconofHimalayaWellness,
  YoutubeIconofHimalayaWellness,
} from '../../assets/images/social';
import { $Flexbox } from '../../css/styles';

export const HimalayaWellnessSocial = () => (
  <React.Fragment>
    {/* <SocialText> Follow us on Social Media <br/>Get 10% Discount on F&B items</SocialText> */}
    <$Flexbox className="Socialalign" justify="space-around" padding="16px 0px" alignItems="center">
      <a
        href="https://www.facebook.com/HimalayaPersonalCare"
        target="_blank"
        rel="noopener noreferrer">
        <FacebookIconofHimalayaWellness style={{ width: '33px', height: '30px' }} />
      </a>
      <a
        href="https://www.instagram.com/himalayapersonalcare/"
        target="_blank"
        rel="noopener noreferrer">
        <InstagramIconofHimalayaWellness style={{ width: '33px', height: '30px' }} />
      </a>
      <a href="https://twitter.com/HimalayaIndia" target="_blank" rel="noopener noreferrer">
        <TwitterIconofHimalayaWellness style={{ width: '30px', height: '30px' }} />
      </a>
      <a
        href="https://www.youtube.com/user/HimalayaHerbalsIndia"
        target="_blank"
        rel="noopener noreferrer">
        <YoutubeIconofHimalayaWellness style={{ width: '30px', height: '30px' }} />
      </a>
    </$Flexbox>
  </React.Fragment>
);
