import styled from 'styled-components';
import HimalayaPointsBg from '../../assets/images/himalaya-wellness/himalaya-points-card.png';
import React from 'react';

const StyledImg = styled.img`
  height: 100%;
  width: 100%;
`;
const StyledCard = styled.div`
  position: relative;
`;

const StyledPoints = styled.div`
  position: absolute;
  color: white;
  margin: 8px 12px;
  text-align: right;
  font-size: 10px;
  letter-spacing: 1px;
  .points {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: normal;
  }
  .no-points {
    font-size: 24px;
    letter-spacing: 4px;
    margin-right: -4px;
  }
`;
const StyledTier = styled.div`
  position: absolute;
  color: white;
  background-color: #f17a20;
  padding: 4px 8px;
  border-radius: 12px;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
`;
const StyledText = styled.p`
  font-size: 12px;
  text-align: center;
  color: white;
`;
const HimalayaPointsCard = ({ rewards }) => {
  const { redeemablePoints, promisedPoints, tier, notSignedUp } = rewards;
  if (notSignedUp) return <></>;
  return (
    <React.Fragment>
      <div className="header himalaya-headerImg" />
      <div style={{ margin: '20px', fontFamily: 'Inter, sans-serif !important' }}>
        {isNaN(redeemablePoints) && isNaN(promisedPoints) ? (
          <StyledText>Unable to load points. Please wait a moment and try again.</StyledText>
        ) : null}
        <StyledCard>
          <StyledImg src={HimalayaPointsBg} alt="Himalaya Wellness Points Bg" />
          <StyledPoints style={{ top: 0, right: 0 }}>
            <div
              className={promisedPoints !== undefined ? `points` : 'no-points'}
              style={{ color: '#F8D25A' }}>
              {promisedPoints ?? '--'}
            </div>
            <div>Promised Points</div>
          </StyledPoints>
          {tier ? <StyledTier>{tier}</StyledTier> : null}
          <StyledPoints style={{ top: '62%', right: 0, transform: 'translateY(-50% )' }}>
            <div className={redeemablePoints !== undefined ? `points` : 'no-points'}>
              {redeemablePoints ?? '--'}
            </div>
            <div>Redeemable Points</div>
          </StyledPoints>
        </StyledCard>
      </div>
    </React.Fragment>
  );
};

export default HimalayaPointsCard;
