import React, { useState, useRef, useEffect, forwardRef, memo, useCallback } from 'react';
import { useTheme } from 'styled-components';
import AnimateHeight from 'react-animate-height';
import { ToggleBillHeight, $GreyText } from '../../../css/styles';
import {
  $Block,
  BlurWhiteOverlay,
  $BSFSurvey,
  $BSFSubmit,
  $DatePickerInput,
} from '../../templates/styles';
import { campaignTypes } from '../../campaigns/constants';
import BillTemplate from './BillTemplate';
import QRCode from 'qrcode.react';
import moment from 'moment';
import { getCategories } from '../data';
import ReactDatePicker from 'react-datepicker';
import useIcons from './Icons';
import { NextBestIcon } from '../../../assets/images/best-seller';
import CinepolisFooter from '../../../assets/images/cinepolis-bill-footer.svg';
import { bgCampaign } from 'utils/campaignbg';

// interface IProps {
//     bestFeed: any;
//     setBestFeed?: Dispatch<SetStateAction<any>>;
//     flags: any;
//     billRatingHt, billImageUrl, isBillLarger, setIsBillLarger, billImageSize, billTemplateSize, setBillTemplateSize, setBillImageSize, campaignDetails, parsedData, brandName, billUid, storeAddress, vh
// }

const Body = forwardRef((props, billTemplateRef) => {
  const theme = useTheme();
  const {
    flags,
    billRatingHt,
    billImageUrl,
    isBillLarger,
    setIsBillLarger,
    billImageSize,
    billTemplateSize,
    setBillTemplateSize,
    setBillImageSize,
    campaignDetails,
    parsedData,
    brandName,
    billUid,
    storeAddress,
    vh,
    bestFeed,
    setBestFeed,
    feedbackElem,
    storeId,
    brandLogo,
    displayAddress,
    createdDate,
    isPaymentStatus,
    other,
    brandId,
  } = props;
  const {
    isMcDonaldsTemplate,
    hideFeedBack,
    isMiraj,
    isCinepolisGroup,
    isBK,
    isBestSeller,
    isNewBillView,
    isNykaaEcom,
    isBkNonEcomm,
    isNykaaStore,
    isMcDFeedback,
    isBaggitFeedback,
    isMODFeedback,
    isMovieMaxTicket,
    isCarnival,
    isCinemaBill,
    isConnplex,
    isRzpPos,
    isNyCinemas,
    isGadchiroli,
    isCityOfPatna,
    isKaya,
    isManyavar,
    isWowMomos,
    isWowChicken,
    isWowKulfi,
    isWowChina,
    isWowFranchise,
    isGoColors,
    showGoogleAdsForCampaigns,
    isRzpFtx25,
    isHimalayaWellness,
  } = flags;
  const isAdOrSellPresent = !!campaignDetails?.filter(
    (camp) => campaignTypes.adBelowBill === camp.type || campaignTypes.sellBelowBill === camp.type,
  ).length;
  const billImgRef = useRef();
  const [bestFeedForm, setBestFeedForm] = useState({ ...bestFeed });

  const multiCoupons = props?.coupons?.multiCoupons || null;

  const {
    getBackIcon,
    getCalendarIcon,
    getCheckEmptyIcon,
    getCheckFilledIcon,
    getRadioEmptyIcon,
    getRadioFilledIcon,
    getThankYouIcon,
  } = useIcons(flags);

  const adjustBillHeight = (forceExecute, manyavanarTaxHeight = 0) => {
    const height = billRatingHt || 100;
    if (
      (isAdOrSellPresent || forceExecute) &&
      !billImageSize &&
      billImageUrl &&
      billImgRef?.current?.offsetHeight > vh - height
    ) {
      // Set view more button only if advert or campaign is present
      if (isAdOrSellPresent && !(isGadchiroli || isCityOfPatna)) {
        setIsBillLarger(isAdOrSellPresent);
      }
      setBillImageSize(vh - height);
    } else if (
      isAdOrSellPresent &&
      !billTemplateSize &&
      billTemplateRef?.current?.offsetHeight > vh - height
    ) {
      if (isAdOrSellPresent && !(isGadchiroli || isCityOfPatna)) {
        if (!isRzpFtx25) {
          setIsBillLarger(isAdOrSellPresent);
        }
      }
      if (isManyavarTaxDetailsClicked) {
        if (showTaxDetails) setBillTemplateSize('');
        else {
          if (!billTemplateSize && manyavanarTaxHeight > 0) {
            setBillTemplateSize(vh + height - manyavanarTaxHeight);
            setBillTemplateSize('');
          } else setBillTemplateSize(vh - height);
        }
      } else setBillTemplateSize(vh - height);
    } else if (showGoogleAdsForCampaigns) {
      setIsBillLarger(true);
      setBillTemplateSize(vh - height);
    }
  };

  const [showTaxDetails, setShowTaxDetails] = useState(false);
  const [showManyavarTaxDetails, setShowManyavarTaxDetails] = useState(0);
  const [isManyavarTaxDetailsClicked, setIsManyavarTaxDetailsClicked] = useState(false);
  const handleClick = async () => {
    await setShowTaxDetails(!showTaxDetails);
    setIsManyavarTaxDetailsClicked(true);
    if (isBillLarger) {
      const div = document.getElementById('adjustHeight');
      const rect = div.getBoundingClientRect();
      const offsetHeight = rect.height;
      if (offsetHeight > 0) {
        setShowManyavarTaxDetails(offsetHeight);
      }
      setIsManyavarTaxDetailsClicked(false);
    }
  };

  useEffect(() => {
    if (isManyavarTaxDetailsClicked) adjustBillHeight(null, showManyavarTaxDetails);
  }, [isManyavarTaxDetailsClicked]);

  useEffect(() => {
    if (isAdOrSellPresent) {
      setTimeout(adjustBillHeight, 1000);
    }
  }, [billRatingHt, isAdOrSellPresent]);

  useEffect(() => {
    if (showGoogleAdsForCampaigns) setTimeout(adjustBillHeight);
  }, [showGoogleAdsForCampaigns]);

  const getBillHeight = () => {
    if (isBillLarger) {
      return (
        billImageSize ||
        billTemplateSize ||
        (isCinemaBill
          ? billTemplateRef?.current?.offsetHeight
          : billTemplateRef?.current?.offsetHeight + 25) ||
        billImgRef?.current.offsetHeight + 25 ||
        'auto'
      );
    }
    return 'auto';
  };

  const getViewMoreTextColorForNewBill = () => {
    if (isKaya) return theme.color.kaya;
    if (isNykaaEcom) return theme.color.nykaa;
    if (isWowChina) return theme.color.wowChina;
    if (isWowChicken) return theme.color.wowChicken;
    if (isWowKulfi) return theme.color.wowKulfi;
    return '#000';
  };
  const getFeedTitle = (bestFeed, type) => {
    if (type === 'bestSeller') {
      if (bestFeed?.categorySubmitted) {
        return bestFeed?.category + (bestFeed?.rating <= 8 ? ' Improvement' : '');
      } else {
        if (bestFeed?.rating <= 8) return 'What should we do to improve your experience?';
        else return 'What was the best part of your shopping experience today?';
      }
    } else if (type === 'nykaa' || type === 'mcd') {
      if (bestFeed?.categorySubmitted) return bestFeed?.category;
      if (bestFeed?.rating <= 6) return 'Where did we go wrong?';
      else if (bestFeed?.rating > 6 && bestFeed?.rating < 9)
        return 'What should we do to improve your experience?';
      else return 'What was the best part of your shopping experience today?';
    }
  };
  function onNextClick() {
    if (bestFeed?.subCategorySubmitted || bestFeedForm?.subCategorySubmitted) {
      if (
        (bestFeedForm?.name && bestFeedForm?.email && bestFeedForm?.date) ||
        (bestFeed?.name && bestFeed?.email && bestFeed?.date)
      ) {
        setBestFeed({ rating: bestFeed?.rating, isFinished: true });
      }
    } else if (bestFeed?.subCategory || bestFeedForm?.subCategory) {
      // setBestFeed({ ...bestFeed, subCategorySubmitted: true })
      setFeedBackChange('subCategorySubmitted', true);
    } else if (bestFeed?.category || bestFeedForm?.category) {
      // setBestFeed({
      //     ...bestFeed,
      //     categorySubmitted: true,
      //     subCategorySubmitted: bestFeed?.category === "Other",
      //     comments: bestFeed?.category === "Other" ? bestFeed?.comments : ""
      // })
      setBestFeedForm({
        ...bestFeedForm,
        categorySubmitted: true,
        subCategorySubmitted: bestFeedForm?.category === 'Other',
        comments: bestFeedForm?.category === 'Other' ? bestFeedForm?.comments : '',
      });
    }
  }

  function setFeedBackChange(key, value) {
    setBestFeedForm({
      ...bestFeedForm,
      [key]: value,
    });
  }

  function onSetSubCategoryArr(subCat) {
    if (bestFeedForm?.subCategory?.includes(subCat)) {
      const subCategory = [...bestFeedForm?.subCategory]?.filter((sc) => sc !== subCat);
      setFeedBackChange('subCategory', subCategory);
    } else {
      const subCategory = [...(bestFeedForm?.subCategory || []), subCat];
      setFeedBackChange('subCategory', subCategory);
    }
  }

  function handleToggleHeight(billType = 'image') {
    if (billType === 'image' && billImageSize) {
      setBillImageSize('');
    }
    if (billType === 'template' && billTemplateSize) {
      setBillTemplateSize('');
    } else {
      adjustBillHeight();
    }
  }

  const displayQrCode = () => {
    const getTaxes = (taxes) => {
      let result = '';

      for (let tax in taxes) {
        switch (tax) {
          case 'cgst':
            result += `\n CGST - ${taxes[tax]}`;
            break;

          case 'sgst':
            result += `\n SGST - ${taxes[tax]}`;
            break;

          case 'utgst':
            result += `\n UTGST - ${taxes[tax]}`;
            break;

          case 'igst':
            result += `\n IGST - ${taxes[tax]}`;
            break;

          default:
            break;
        }
      }
      return result;
    };

    if (isBkNonEcomm) {
      const { customData: { qrCode } = {} } = parsedData;

      if (!qrCode) return null;

      return (
        <div style={{ textAlign: 'center' }}>
          <QRCode value={qrCode} />
        </div>
      );
    }
  };
  const getActiveStore = () => {
    if (isBestSeller || isBaggitFeedback || isMODFeedback) return 'bestSeller';
    if (isNykaaStore) return 'nykaa';
    if (isMcDFeedback) return 'mcd';
  };
  const npsForm = () => {
    return (
      <$BSFSurvey isNykaaStore={isNykaaStore} isMcDFeedback={isMcDFeedback}>
        <h2>Survey</h2>
        <h4 onClick={() => setBestFeed({})}>{getBackIcon()} Back to Your Bill</h4>
        {!bestFeedForm?.subCategorySubmitted && (
          <>
            <p>{getFeedTitle(bestFeedForm, getActiveStore())}</p>

            {!bestFeedForm?.categorySubmitted && (
              <div>
                {Object.keys(getCategories(getActiveStore(), bestFeedForm?.rating)).map((cat) => (
                  <span key={cat} onClick={() => setFeedBackChange('category', cat)}>
                    <big>
                      {bestFeedForm?.category === cat ? getRadioFilledIcon() : getRadioEmptyIcon()}
                    </big>
                    <label htmlFor={cat}>{cat}</label>
                  </span>
                ))}
              </div>
            )}

            {bestFeedForm?.categorySubmitted &&
              getCategories(getActiveStore(), bestFeedForm?.rating)?.[bestFeedForm?.category]
                ?.length && (
                <div>
                  {getCategories(getActiveStore(), bestFeedForm?.rating)?.[
                    bestFeedForm?.category
                  ]?.map((cat) => (
                    <span key={cat} onClick={() => onSetSubCategoryArr(cat)}>
                      <big>
                        {bestFeedForm?.subCategory?.includes(cat)
                          ? getCheckFilledIcon()
                          : getCheckEmptyIcon()}
                      </big>
                      <label htmlFor={cat}>{cat}</label>
                    </span>
                  ))}
                </div>
              )}

            {(bestFeedForm?.category === 'Other' || bestFeedForm?.categorySubmitted) && (
              <div className="comment">
                <p>Comments</p>
                <textarea
                  rows={5}
                  value={bestFeedForm?.comments || ''}
                  onChange={(e) => setFeedBackChange('comments', e?.target?.value)}
                  placeholder="Please elaborate on your experience; it will really motivate our team to serve you better."
                />
              </div>
            )}

            <$BSFSubmit
              isNykaaStore={isNykaaStore}
              isMcDFeedback={isMcDFeedback}
              onClick={onNextClick}>
              {bestFeedForm?.categorySubmitted ? 'Submit' : <NextBestIcon />}
            </$BSFSubmit>
          </>
        )}
        {bestFeedForm?.subCategorySubmitted && (
          <>
            <div className="feedback">
              <div className="flex">
                {getThankYouIcon()}
                <h3>Thank You For Your Valuable Feedback</h3>
              </div>

              <p>Please Share Your Details:</p>

              <label>Name</label>
              <input
                placeholder="Please Enter Your Name."
                value={bestFeedForm?.name || ''}
                onChange={(e) => setFeedBackChange('name', e.target.value)}
              />

              <label>Email</label>
              <input
                type="email"
                placeholder="Please Enter Your Email ID."
                value={bestFeedForm?.email || ''}
                onChange={(e) => setFeedBackChange('email', e.target.value)}
              />

              <label>Date of Birth</label>
              <ReactDatePicker
                dateFormat="dd/MM/yyyy"
                selected={bestFeedForm?.date || ''}
                onChange={(date) => setFeedBackChange('date', date)}
                customInput={
                  <$DatePickerInput>
                    <input
                      readOnly
                      placeholder="DD/MM/YYYY"
                      value={
                        bestFeedForm?.date
                          ? moment(bestFeedForm?.date || new Date()).format('DD/MM/YYYY')
                          : ''
                      }
                    />
                    {getCalendarIcon()}
                  </$DatePickerInput>
                }
              />
            </div>
            <$BSFSubmit
              isNykaaStore={isNykaaStore}
              isMcDFeedback={isMcDFeedback}
              isCentered
              onClick={onNextClick}>
              Submit & Go Back To Your Bill
            </$BSFSubmit>
          </>
        )}
      </$BSFSurvey>
    );
  };

  return (
    <>
      {typeof bestFeed?.rating == 'number' && !bestFeed?.isFinished ? (
        <>{npsForm()}</>
      ) : (
        <$Block
          margin={
            isMovieMaxTicket
              ? 0
              : `${hideFeedBack ? theme.spacing.m : '0'} ${isBK ? '' : theme.spacing.xl}`
          }
          className={`bill-app-body ${isBK ? 'bk-body' : ''}`}
          top={bgCampaign(campaignDetails, parsedData).top}
          style={
            isRzpPos
              ? { marginTop: '-18px', backgroundColor: 'white' }
              : isKaya
                ? { border: '1px solid #D3D3D3', margin: '0px' }
                : {}
          }
          onError={() => {
            if (!localStorage.getItem(`${billUid}-reload`)) {
              localStorage.setItem(`${billUid}-reload`, true);
              window.location.reload();
            }
          }}>
          <div
            className={`${(isManyavar, isGoColors && !billTemplateSize ? 'manyavarViewLess' : '')}`}
            style={{ position: 'relative', background: (isCarnival || isRzpFtx25) && '#FFF' }}>
            <AnimateHeight easing="ease" duration={700} height={getBillHeight()}>
              <div
                className={`bill-img ${isBK ? 'bill-tear' : ''}`}
                style={
                  isManyavar || isGoColors || isWowMomos || isWowFranchise
                    ? { backgroundColor: 'white', padding: '5px' }
                    : {}
                }
                ref={billImgRef}>
                <div
                  className={
                    isConnplex && parsedData?.customData?.ticketType === 'CINEPOLIS_TICKET'
                      ? 'connplex-layoutset'
                      : ''
                  }
                />
                <BillTemplate
                  flags={flags}
                  billImageUrl={billImageUrl}
                  brandName={brandName}
                  parsedData={parsedData}
                  billUid={billUid}
                  adjustBillHeight={adjustBillHeight}
                  size={billImgRef?.current?.offsetWidth}
                  storeAddress={storeAddress}
                  ref={billTemplateRef}
                  multiCoupons={multiCoupons}
                  feedbackElem={feedbackElem}
                  storeId={storeId}
                  brandLogo={brandLogo}
                  displayAddress={displayAddress}
                  createdDate={createdDate}
                  isPaymentStatus={isPaymentStatus}
                  other={other}
                  onClick={handleClick}
                  showTaxDetails={showTaxDetails}
                  brandId={brandId}
                />
                {displayQrCode()}
              </div>
              {(billImageSize || billTemplateSize) && isBillLarger && !isMiraj && (
                <BlurWhiteOverlay />
              )}
            </AnimateHeight>
          </div>

          {isBillLarger && (
            <ToggleBillHeight
              isNewBillView={isNewBillView}
              isBK={isBK}
              isManyavar={isManyavar}
              isRzpFtx25={isRzpFtx25}
              isGoColors={isGoColors}
              isCarnival={isCarnival}
              isWowMomos={isWowMomos}
              isWowFranchise={isWowFranchise}
              isBestSeller={isBestSeller}
              isCinepolisGroup={isCinepolisGroup}
              isMiraj={isMiraj}
              isHimalayaWellness={isHimalayaWellness}
              color={getViewMoreTextColorForNewBill()}
              type="button"
              onClick={() => handleToggleHeight(billImageSize ? 'image' : 'template')}
              className={`media-no-print ${isNyCinemas && 'media-no-print-nycine'}`}>
              {billImageSize || billTemplateSize ? 'View More' : 'View Less'}
            </ToggleBillHeight>
          )}
          {isMiraj && !isConnplex && <img alt="" src={CinepolisFooter} className="miraj-footer" />}
        </$Block>
      )}
    </>
  );
});

export default memo(Body);
