import React, { useState, useEffect } from 'react';
import filledSmileys1 from '../../assets/images/smileys/Emojis Filled/Terrible - filled.png';
import filledSmileys2 from '../../assets/images/smileys/Emojis Filled/Bad - filled.png';
import filledSmileys3 from '../../assets/images/smileys/Emojis Filled/Average - filled.png';
import filledSmileys4 from '../../assets/images/smileys/Emojis Filled/Good - filled.png';
import filledSmileys5 from '../../assets/images/smileys/Emojis Filled/Excellent - filled.png';
import filledSmileys6 from '../../assets/images/smileys/Emojis Filled/Lovely-Filled.svg';
import smileys1 from '../../assets/images/smileys/Emojis Unfilled/Terrible - Unfilled.png';
import smileys2 from '../../assets/images/smileys/Emojis Unfilled/Bad - Unfilled.png';
import smileys3 from '../../assets/images/smileys/Emojis Unfilled/Average - Unfilled.png';
import smileys4 from '../../assets/images/smileys/Emojis Unfilled/Good - Unfilled.png';
import smileys5 from '../../assets/images/smileys/Emojis Unfilled/Excellent - Unfilled.png';
import smileys6 from '../../assets/images/smileys/Emojis Unfilled/Lovely-Unfilled.svg';
import emojiv2AbsolutelyUF from '../../assets/images/smileys/Emojis Unfilled/Absolutely - Unfilled.svg';
import emojiv2AbsolutelyF from '../../assets/images/smileys/Emojis Filled/Absolutely - Filled.svg';
import emojiv2MaybeUF from '../../assets/images/smileys/Emojis Unfilled/Maybe - Unfilled.svg';
import emojiv2MaybeF from '../../assets/images/smileys/Emojis Filled/Maybe - filled.svg';
import emojiv2NotLikelyUF from '../../assets/images/smileys/Emojis Unfilled/Not likely - Unfilled.svg';
import emojiv2NotLikelyF from '../../assets/images/smileys/Emojis Filled/Not Likely - Filled.svg';
const smileyStyles = {
  width: '30px',
  margin: '0 15px',
};
const smileyStylesfor10 = {
  width: '25px',
  margin: '0 6px',
};

const SmileyRating = ({
  feedbackExist,
  feedbackRating,
  onFeedbackRatingChange,
  feedbackSettings,
  isReverseOrder,
  isHimalayaWellness,
}) => {
  const unfilled10 = [
    smileys1,
    smileys1,
    smileys2,
    smileys2,
    smileys3,
    smileys3,
    smileys4,
    smileys4,
    smileys6,
    smileys6,
  ];
  const filled10 = [
    filledSmileys1,
    filledSmileys1,
    filledSmileys2,
    filledSmileys2,
    filledSmileys3,
    filledSmileys3,
    filledSmileys4,
    filledSmileys4,
    filledSmileys6,
    filledSmileys6,
  ];
  const unfilled5 = [smileys1, smileys2, smileys3, smileys4, smileys5];
  const filled5 = [filledSmileys1, filledSmileys2, filledSmileys3, filledSmileys4, filledSmileys5];
  const unfilled3 = [emojiv2NotLikelyUF, emojiv2MaybeUF, emojiv2AbsolutelyUF];
  const filled3 = [emojiv2NotLikelyF, emojiv2MaybeF, emojiv2AbsolutelyF];
  const unfilled2 = [emojiv2NotLikelyUF, emojiv2AbsolutelyUF];
  const filled2 = [emojiv2NotLikelyF, emojiv2AbsolutelyF];

  const [rating, setRating] = useState(feedbackRating || 0);
  const [emojiArr, setEmojiArr] = useState();

  const handleSelection = (e) => {
    if (feedbackExist) return;
    onFeedbackRatingChange(e.target.attributes.value.value);
    setRating(Number(e.target.attributes.value.value));
  };

  useEffect(() => {
    const newEmojiArr = getEmojiArr();
    setEmojiArr(newEmojiArr);
  }, [rating]);
  const getEmojiArr = () => {
    const unfilledEmoji = {
      10: unfilled10.map((x, i) => (
        <img
          src={x}
          className="star-icon smiley-icon"
          alt="Rating smiley"
          onClick={handleSelection}
          value={i + 1}
          style={smileyStylesfor10}
        />
      )),
      5: unfilled5.map((x, i) => (
        <img
          src={x}
          className="star-icon smiley-icon"
          alt="Rating smiley"
          onClick={handleSelection}
          value={i + 1}
          style={smileyStyles}
        />
      )),
      3: unfilled3.map((x, i) => (
        <img
          src={x}
          className="star-icon smiley-icon"
          alt="Rating smiley"
          value={i + 1}
          onClick={handleSelection}
          style={smileyStyles}
        />
      )),
      2: unfilled2.map((x, i) => (
        <img
          src={x}
          className="star-icon smiley-icon"
          alt="Rating smiley"
          value={i + 1}
          onClick={handleSelection}
          style={smileyStyles}
        />
      )),
    };
    const filledEmoji = {
      10: filled10.map((x) => (
        <img
          src={x}
          className="star-icon smiley-icon"
          alt="Rating smiley"
          style={smileyStylesfor10}
        />
      )),
      5: filled5.map((x) => (
        <img src={x} className="star-icon smiley-icon" alt="Rating smiley" style={smileyStyles} />
      )),
      3: filled3.map((x) => (
        <img src={x} className="star-icon smiley-icon" alt="Rating smiley" style={smileyStyles} />
      )),
      2: filled2.map((x) => (
        <img src={x} className="star-icon smiley-icon" alt="Rating smiley" style={smileyStyles} />
      )),
    };
    const length =
      feedbackSettings?.scaleLength && feedbackSettings?.scaleLength <= 10
        ? feedbackSettings?.scaleLength
        : '5';
    const result = unfilledEmoji[length].map((x, i) => {
      return isHimalayaWellness
        ? i + 1 <= rating
          ? filledEmoji[length][i]
          : x
        : i + 1 === rating
          ? filledEmoji[length][i]
          : x;
    });
    console.log('isReverseOrder', isReverseOrder, result);
    console.log('isReverseOrder', rating);
    return isReverseOrder ? result.reverse() : result; // : filledEmoji[length]
  };

  return <>{emojiArr}</>;
};

export default SmileyRating;
