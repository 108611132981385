import { customFrontendId } from './custom-frontend-id';
import { brandIds, companyIds, showVaccineBannerForBrand } from '../components/billView/data';
import { VendorFlags } from './types';

type Params = {
  brandId: string;
  storeId: string;
  companyId: string;
  customId: string;
  parsedData: any;
  isEcomBill: boolean;
};

const customVendorFlags = ({
  brandId,
  storeId,
  companyId,
  customId,
  parsedData,
  isEcomBill,
}: Params): VendorFlags => {
  const isCinepolisGroup = [
    customFrontendId.cinepolisTicket,
    customFrontendId.cinepolisFood,
    customFrontendId.cinepolisMexicoTicket,
    customFrontendId.cinepolisIndia,
  ].includes(customId);
  const isCinepolisTicket =
    isCinepolisGroup &&
    (parsedData?.customData?.ticketType === 'CINEPOLIS_TICKET' ||
      customId === customFrontendId.cinepolisTicket);
  const isCinepolisFoodBill =
    isCinepolisGroup &&
    (parsedData?.customData?.ticketType === 'CINEPOLIS_FOOD' ||
      customId === customFrontendId.cinepolisFood);
  const isCinepolisMexicoTicket = customId === customFrontendId.cinepolisMexicoTicket;
  const isDecathlonLanka =
    companyId === companyIds.decathlonLanka || customId === customFrontendId.decathlonSriLanka;
  const isMarcaEcomm =
    isEcomBill &&
    (brandIds?.marcaDisati?.includes(brandId) || customId === customFrontendId.marcaDisati);
  const isDecathlonTemplate = [customFrontendId.decathlon].includes(customId);
  const isCCD = customId === customFrontendId.CCD;
  const isDecathlonIndiaEcom =
    (brandIds?.decathlon?.includes(brandId) || customId === customFrontendId.decathlonIndia) &&
    isEcomBill;
  const isShree = brandIds?.shreeTIA?.includes(brandId) || customId === customFrontendId.shree;
  const isBK = brandIds?.burgerKing?.includes(brandId) || customId === customFrontendId.bKIndia;
  const isFunCinemas = brandIds?.funCinemas?.includes(brandId);
  const isRRGold = brandIds?.rrGold?.includes(brandId) || customId === customFrontendId.rrGold;
  const isPrasads = customId === customFrontendId.prasads;
  const isNykaaEcom =
    isEcomBill && (brandIds?.nykaa?.includes(brandId) || customId === customFrontendId.nykaaIndia);
  const isDinapala =
    brandIds?.dinapala?.includes(brandId) || customId === customFrontendId.dinapala;
  const isEsbeda = customId === customFrontendId.esbeda;
  const isStylyn = brandId === '2up8vsl8ikoghv';
  const isMomaiya = brandId === '2bajwkl8sddx1e';
  const isSPRVLCrocodile = brandId === 'kl4mplg8xfel3';
  const isSPRVLAngelAndRocket = brandId === '8qr5evklg8xhbsz';
  const isEemosSalon = brandId === 'j6cugjkqkr92so';
  const isConnplex = ['2gtfxjl92oy6jf', '6umkusrlbafunmd', 'kmqqlfi8kon7'].includes(brandId);
  const isWatersEdge = brandId === '39kjkr68slmj';
  const isWestside = brandId === 'edzojljtuum3a' || brandId === '9k3iktlvc2897n'; //TEST
  const isZudio =
    brandId === '4w0h5klk0tpe3c' || brandId === 'dcopljsc8gbg' || brandId === '9k3iktlvc1n9nb'; //test;
  const isSamoh = brandId === '4w0h5klk8823tp' || brandId === '9k3iktlvc2e3yh'; //test;
  const isStarbazzar = brandId === 'edzojlk13iytk' || brandId === '9k3iktlvc2btma'; //test;
  const isRoongta =
    brandId === '2eh7j60jlfc4n59r' || companyIds?.roongtaCinemas?.includes(companyId);
  const isMisbu = brandId === 'kkslkjllkg1heg' || brandId === '9k3iktlvc21ft7'; //test;
  const isRajhans = companyId === '34uf6jlnabalrz';
  const isRzpFtx25 = customId === customFrontendId.rzpFtx25;
  const isRzpPos = companyId === 'kkslkjlm4nxikr' && !isRzpFtx25;
  const isSangamSelection = brandId === 'ycxjlqf0hn8q';
  const isSuperK = customId === customFrontendId.superK;
  const isNainpreet =
    process.env.REACT_APP_ENV === 'PROD'
      ? companyId === '8ymlvlv0zella'
      : companyId === 'nygrw1khk6j7cmy1'; //for stage shopify test

  const isCinemaBill = customId === customFrontendId.cinemaBill;
  const isFoodBill = parsedData?.customData?.ticketType === 'CINEPOLIS_FOOD';
  const isMovieMaxTicket = isCinemaBill && companyId === companyIds.movieMax;
  const isMiraj = isCinemaBill && companyId === companyIds.miraj;
  const isCarnival = isCinemaBill && companyId === companyIds.carnival;
  const isShilpiBill = isCinemaBill && companyId === companyIds.shilpi;
  const isTrent =
    companyId === '4w0h5kljtutxvy' ||
    companyId === 'dcopljsc7efd' ||
    companyId === '9k3iktlvc3i473'; //test;
  const isFioraHyperMarket =
    companyId === '3rhriulriyoqqp' ||
    companyId === 'dcopljsc7efd' ||
    companyId === '9k3iktlvc1hhr0'; //test
  const isTrentHyperMarket =
    companyId === '3zfxcqloh96j2c' ||
    companyId === 'dcopljsc7efd' ||
    companyId === '9k3iktlvcfdwcv'; //test
  const isJalsaCarnival = brandId === '2bajwkl8u4xqye';
  const isHalt = companyId === companyIds.halt;
  const isF6Fashion = companyId === 'y9z3jlgf00lm5';
  const isF6FashionWomen = companyId === 'y9z3jlgf00lm5' && brandId === 'aye96mulvc7yi1i';
  const isBodyBuildingIndia = companyId === '4w0h5klks0w2mq';
  const isHdfChargeSlip = customId === customFrontendId.hdfc;
  const isKaya = customId === customFrontendId.kaya;
  const isUtsa = customId === customFrontendId.utsa;
  const isDemoCinepolis = storeId === '114ljculsuc3lg2';
  const isDemoWestside =
    process.env.REACT_APP_ENV === 'PROD' ? storeId === 'ibutqlsbh6e2d' : storeId === 'dcopljsca1t7'; //for stage

  // const isMagsons = brandIds?.magsons?.includes(brandId)
  const isMcDonaldsTemplate = customId === customFrontendId.mcd;
  const isBestSeller =
    brandIds?.bestSeller?.includes(brandId) || customId === customFrontendId.bestSeller;
  const isBaggitFeedback = storeId === '41yfja9q8keo1gxkd';
  const isMODFeedback = storeId === 'fmaz1kgk6n8e6zj';
  const isKompaneroFeedback = storeId === '4corql1w1ti87';
  const isRareRabbitFeedback = storeId === '1m4ahokb8zhevw';
  const isDSIAnubhava = storeId === '4gb0i29jkl7v524h';
  const isMcDFeedback = storeId === 'm6k3y6k7k3xq8a';
  const isShowLoyaltyCard =
    ['4w0h5kljva7n05', 'edzojlk0tuddy'].includes(storeId) || brandId === 'dcopljsc8gbg'; //test;
  const isNyCinemas = companyId === '4w0h5klky88f0v' || brandId === 'tiw2jls8rbijq';
  const isMahavirStore = companyId === '4corql1vyhikz';

  const isNykaaStore = customId === customFrontendId.nykaaFeedbackTest;
  const isMcDonalds = brandIds?.mcDonalds?.includes(brandId);

  const isChineseWok = companyId === '1dc47zum2kqtyze' || companyId === '1wnrulyy02yzg';
  const isNPSFeedback =
    isBestSeller || isNykaaStore || isMcDFeedback || isBaggitFeedback || isMODFeedback;

  const isMcdGroup = isMcDonaldsTemplate || isMcDonalds;
  const isCentro = brandIds?.centro?.includes(brandId) || customId === customFrontendId.centro;
  const isVeltosa = brandIds?.veltosa?.includes(brandId) || customId === customFrontendId.veltosa;
  const isNuttysDen =
    brandIds?.isNuttysDen?.includes(brandId) || customId === customFrontendId.nuttysDen;

  const isCentroGrande =
    brandIds?.centroGrande?.includes(brandId) || customId === customFrontendId.centroGrande;

  const isBkEcomm = isBK && isEcomBill;
  const isRelaxo = customId === customFrontendId.relaxo;

  const isTWC = customId === customFrontendId.twc;

  const isBkNonEcomm = isBK && !isEcomBill;

  const isPdf = customId === customFrontendId.pdf;

  const showRating = isNykaaStore;
  const showFooter = !isCinepolisTicket && !isCinepolisFoodBill;
  const animateBillHeight = !brandIds?.cinepolisGulf?.includes(brandId);

  const showVaccineBanner = showVaccineBannerForBrand.includes(brandId);

  const isLE15 = brandIds?.le15?.includes(brandId);

  const isEcomThirdParty = customId === customFrontendId.ecomPlatform && isEcomBill;

  const isNewBillView = !isCinepolisGroup && !isMiraj && !isBK && !isShree;

  const isGoColors = customId === customFrontendId.goColors;
  const isWowChina =
    process.env.REACT_APP_ENV !== 'PROD'
      ? ['PorLmfjfVmHNJ9', '182hum6evhoip'].includes(brandId)
      : ['1hwuvm2st17hw'].includes(brandId);
  const isWowChicken =
    process.env.REACT_APP_ENV !== 'PROD'
      ? ['PpJHjnXjk05eOk'].includes(brandId)
      : ['1hwuvm2st2c9i'].includes(brandId);
  const isWowKulfi =
    process.env.REACT_APP_ENV !== 'PROD'
      ? ['PpJIrx46jIRcDb'].includes(brandId)
      : ['1hwuvm2st87m8'].includes(brandId);
  const isWowMomos = [
    '178t5uulx1p3bon',
    '1hwuvm2st7jv7',
    'Q0h45qD1gOZoU9',
    'PxAvSCQkq0pUUq',
  ].includes(brandId);
  const isWowFranchise = isWowChina || isWowChicken || isWowKulfi || isWowMomos;
  const showDownloadAppText =
    isDecathlonIndiaEcom ||
    isNykaaEcom ||
    isCinepolisGroup ||
    isHalt ||
    isBodyBuildingIndia ||
    isHdfChargeSlip ||
    isDemoCinepolis ||
    isWowFranchise ||
    isWestside;
  const showMetaText = isDecathlonIndiaEcom || isNykaaEcom ? 'Shop Online' : '';
  const hideFooterButton = isMarcaEcomm && !parsedData?.transactionalData?.deliveryStatusUrl;
  const isMeNMoms = brandIds?.menmoms?.includes(brandId);

  const isDeluxCaterers = companyIds?.deluxCaterers === companyId;
  const isValuePlusRetail = brandIds?.valuePlusRetail?.includes(brandId);
  const isHastimalManikchand = companyIds.hastimalManikchand === companyId;
  const isInventis = companyIds.inventis === companyId;
  const isBeautynBeyond = customId === customFrontendId.beautynBeyond;
  const isGadchiroli = customId === customFrontendId?.gadchiroli;
  const isBewakoof =
    customId === customFrontendId?.bewakoof || customId === customFrontendId?.bewakoofStage;

  const isRxWellPharmacy = companyId === companyIds.rxwellPharmacy;

  const isCoussouq = companyId === companyIds.coussouq;

  const isRawMango = customId === customFrontendId.rawMango;
  const isSPRVL = customId === customFrontendId.sprvl;
  const isDecathIndPlay = customId === customFrontendId.decathIndPlay;
  const isCinepolisIndonesia =
    process.env.REACT_APP_ENV !== 'PROD'
      ? companyId === '113y9tvltscdg69'
      : companyId === 'kbpwultckqyik';

  const isMulmul =
    process.env.REACT_APP_ENV !== 'PROD'
      ? companyId === '1595rqvlvmbhdlf' // stage company ID
      : companyId === '4vxurluluqskusj'; // prod companyID
  const isCityOfPatna = customId === customFrontendId.cityOfPatna;

  const isDefaultEcomV2 = customId === customFrontendId.v2EcomApi;
  const isTFSCompany = companyId === 'ipbbrlemt1dhc' || companyId === 'aojj9eum163yr4t';

  const isBillMeDemo = companyId === 'nygrw1khk6j7cmy1';

  const isFoodOrderLinkforRajhans = storeId === '97jcjlnad94fx';
  const isManyavar = customId === customFrontendId.manyavar;

  const isWonderSoft = customId === customFrontendId.wonderSoft;
  const isCineport = companyId === '4w0h5klisjsy4i';

  const isKpn = companyId === '4wjtrum2n1fsvn';

  const isMiArcus =
    process.env.REACT_APP_ENV === 'PROD'
      ? companyId === '1jcb6um48qba6o'
      : companyId === '3q5dvum50popuc'; //stage

  const isZimson =
    process.env.REACT_APP_ENV === 'PROD'
      ? companyId === '7oi5cbum4jyhbvi'
      : companyId === 'fr9jum56eppcl'; //stage;

  const isSammm =
    process.env.REACT_APP_ENV === 'PROD'
      ? companyId === '43h4e7um04x5l46'
      : companyId === '1hjgboum04xegw5'; //stage

  const isSugarCosmetics =
    process.env.REACT_APP_ENV === 'PROD'
      ? companyId === 'adprl4o5ngd1'
      : companyId === 'qjjboum1q7wh6x'; //stage

  const isHouseofFett =
    process.env.REACT_APP_ENV === 'PROD'
      ? brandId === '2696jtm1aehloa'
      : brandId === '14qum1t0hs3i'; //stage

  const isTwamev =
    process.env.REACT_APP_ENV === 'PROD'
      ? brandId === '1d6jum0cem5mh'
      : brandId === '151ooum0xt32pg'; //stage
  const isMebaz =
    process.env.REACT_APP_ENV === 'PROD'
      ? brandId === '1d6jum0ceg85h'
      : brandId === '151ooum0xt3o5b'; //stage
  const isMohey =
    process.env.REACT_APP_ENV === 'PROD'
      ? brandId === '3525cv3um0cee4a6'
      : brandId === '151ooum0xt0pth'; //stage
  const isManyavarMohey =
    process.env.REACT_APP_ENV === 'PROD'
      ? brandId === '1d6jum0cednr1'
      : brandId === '151ooum0xt1lz4'; //stage
  const isManyavarbrand =
    process.env.REACT_APP_ENV === 'PROD'
      ? brandId === '1d6jum0cec1x4'
      : brandId === '2crvulztrlt7h'; //stage

  const isHimalayaWellness =
    process.env.REACT_APP_ENV === 'PROD'
      ? companyId === 'jqo1um5c99tyn'
      : companyId === 'vq7nvum28q2r9i'; //stage

  const isPureHomeNLiving =
    process.env.REACT_APP_ENV === 'PROD'
      ? brandId === '44ct2tm5up8rpr'
      : brandId === '24vcum5rpayze'; //stage

  //common flags
  const hideBillTear =
    isCinepolisGroup ||
    isMiraj ||
    isBK ||
    (isConnplex && parsedData?.customData?.ticketType === 'CINEPOLIS_FOOD') ||
    isRzpFtx25 ||
    isWowFranchise ||
    isSammm ||
    isRawMango;
  const showTnCModal =
    isPrasads ||
    isBestSeller ||
    isCinepolisGroup ||
    isMiraj ||
    isMovieMaxTicket ||
    isRelaxo ||
    isConnplex ||
    isZudio ||
    isSamoh ||
    isRoongta ||
    isNyCinemas ||
    isKaya ||
    isSuperK ||
    isBewakoof ||
    isManyavar ||
    isWowMomos ||
    isHouseofFett ||
    isGoColors ||
    isRajhans ||
    isHimalayaWellness ||
    isWowFranchise ||
    isPureHomeNLiving;

  const isNYBRow = showTnCModal || isBK || isDecathlonIndiaEcom;
  const hideFeedBack =
    (isNykaaEcom && !isNykaaStore) || isVeltosa || isNuttysDen || isRawMango || isHdfChargeSlip;
  const fbThroughSurvey = false; //for moving survey placeholder in feedback section.

  const showTotalSavingsTop = isStarbazzar;
  const appendStoreCodeToInvoice = isFioraHyperMarket || isTrent || isTrentHyperMarket || isUtsa;
  const appendCompAddressToStoreAddress =
    isFioraHyperMarket || isTrent || isTrentHyperMarket || isUtsa;
  const isTrentBrands =
    isZudio || isTrent || isUtsa || isSamoh || isMisbu || isStarbazzar || isWestside;
  const payNowThroughRzp = isNainpreet;

  const showGoogleAdsForCampaigns = isBillMeDemo; //|| isMiraj;
  const shouldDownloadPngImg = isZimson;
  const hasCustomLoader = isWowChina;
  const isRewardPointsEnabled = isHimalayaWellness;
  return {
    isTFSCompany,
    isCinepolisGroup,
    isCinepolisTicket,
    isCinepolisFoodBill,
    isCinepolisMexicoTicket,
    isCinepolisIndonesia,
    isDecathlonLanka,
    isMarcaEcomm,
    isDecathlonTemplate,
    isDecathlonIndiaEcom,
    isCCD,
    isShree,
    isBK,
    isFunCinemas,
    isRRGold,
    isPrasads,
    isNykaaEcom,
    isDinapala,
    isEsbeda,
    isFoodBill,
    isMovieMaxTicket,
    isMiraj,
    isMcDonaldsTemplate,
    isBestSeller,
    isBaggitFeedback,
    isMODFeedback,
    isKompaneroFeedback,
    isRareRabbitFeedback,
    isMcDFeedback,
    isNykaaStore,
    isMcDonalds,
    isNPSFeedback,
    isMcdGroup,
    isCentro,
    isVeltosa,
    isCentroGrande,
    isBkEcomm,
    isBkNonEcomm,
    isMeNMoms,
    isPdf,
    isNyCinemas,
    showRating,
    showFooter,
    animateBillHeight,
    showVaccineBanner,
    hideBillTear,
    showTnCModal,
    isNYBRow,
    hideFeedBack,
    isLE15,
    isEcomThirdParty,
    isNewBillView,
    showDownloadAppText,
    showMetaText,
    hideFooterButton,
    isEcomBill,
    isRelaxo,
    isDeluxCaterers,
    isValuePlusRetail,
    isNuttysDen,
    isHalt,
    isCarnival,
    isHastimalManikchand,
    isInventis,
    isBeautynBeyond,
    isStylyn,
    isMomaiya,
    isJalsaCarnival,
    isGadchiroli,
    isEemosSalon,
    isCinemaBill,
    isShilpiBill,
    isConnplex,
    isRxWellPharmacy,
    isCoussouq,
    isDSIAnubhava,
    isWatersEdge,
    isRawMango,
    isSPRVLCrocodile,
    isSPRVLAngelAndRocket,
    isF6Fashion,
    isF6FashionWomen,
    isTrent,
    isZudio,
    isSamoh,
    isSPRVL,
    isStarbazzar,
    isMisbu,
    isRoongta,
    isShowLoyaltyCard,
    isTrentHyperMarket,
    appendCompAddressToStoreAddress,
    appendStoreCodeToInvoice,
    showTotalSavingsTop,
    isBodyBuildingIndia,
    isHdfChargeSlip,
    isUtsa,
    isMahavirStore,
    isDemoCinepolis,
    isDemoWestside,
    isRajhans,
    isRzpPos,
    fbThroughSurvey,
    isSangamSelection,
    isDecathIndPlay,
    isTrentBrands,
    isKaya,
    isNainpreet,
    isMulmul,
    isSuperK,
    payNowThroughRzp,
    isDefaultEcomV2,
    isCityOfPatna,
    isWestside,
    isBewakoof,
    isTWC,
    showGoogleAdsForCampaigns,
    isManyavar,
    isTwamev,
    isMebaz,
    isMohey,
    isManyavarMohey,
    isManyavarbrand,
    isWowMomos,
    isHouseofFett,
    isFoodOrderLinkforRajhans,
    isGoColors,
    isWonderSoft,
    isChineseWok,
    isRzpFtx25,
    isKpn,
    isCineport,
    isZimson,
    isSugarCosmetics,
    isMiArcus,
    isSammm,
    shouldDownloadPngImg,
    isHimalayaWellness,
    isWowChina,
    hasCustomLoader,
    isWowFranchise,
    isWowChicken,
    isWowKulfi,
    isPureHomeNLiving,
    isRewardPointsEnabled,
  };
};

export default customVendorFlags;
