import styled, { css } from 'styled-components';

export const $CampaignContainer = styled.div`
  max-width: 100%;
  max-height: ${(props) => props.maxHeight};
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  img, video {
    display: block;
    margin: auto;
    /*object-fit: contain;*/
    max-height: ${(props) => props.maxHeight}
    background-color: #FFF;
	width:100%;
    max-width: 100%;
  }
  video {
    background-color: #000;
  }
  a {
    position: absolute;
    top: 0px;
    left: 0px;
    min-width: 100%;
    min-height: 100%;
    z-index: 10;
  }
`;
export const $YoutubeContainer = styled.div`
  iframe {
    max-width: 100%;
    max-height: 300px;
    display: block;
  }
`;
export const $PdfContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  canvas {
    max-width: calc(100% - 24px);
    margin: auto;
  }
`;

export const $Coupon = styled.div`
  padding-top: ${(props) => props.aspectRatio};
  color: ${(props) => props.color || '#3B86FF'};
  font-size: ${(props) => (props.fontSize ? props.fontSize + 'px' : '12px')};
  font-family: ${(props) => props.fontFamily};
  max-width: 100%;
  min-width: 100px;
  position: absolute;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  z-index: 10;
  svg {
    width: ${(props) => (props.fontSize ? props.fontSize + 'px' : '12px')};
    height: ${(props) => (props.fontSize ? props.fontSize + 'px' : '13px')};
    path {
      fill: ${(props) => props.color || '#3B86FF'};
    }
  }

  ${({ position }) =>
    position === 'topLeft'
      ? css`
          left: 0;
          top: 0;
        `
      : position === 'bottomLeft'
        ? css`
            left: 0;
            bottom: 0;
          `
        : position === 'bottomRight'
          ? css`
              right: 0;
              bottom: 0;
            `
          : position === 'center'
            ? css`
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              `
            : css`
                right: 0;
                top: 0;
              `};
`;

export const $SIBContainer = styled.a`
  position: relative;
  color: unset;
  text-decoration: unset;
  margin: 10px 0;
  max-height: 450px;
  display: flex;
  justify-content: center;
  overflow: auto;
  margin-top: ${(props) => props.marginTop};
  img {
    max-width: 100%;
    object-fit: contain;
  }
`;

export const $MarginContainer = styled.div`
  width: 96%;
  margin: 5% 2%;
`;

export const $MarginContainerWithBorder = styled.div`
  background: #ffffff;
  border-radius: 8px;
  height: auto;
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};
  margin: 12px;

  ${({ isPOB, isButtonPresent }) =>
    isPOB &&
    css`
      width: auto;
    `}
`;

export const $CarouselContainer = styled.div`
  position: relative;
  padding: 12px;
  .carousel .control-dots {
    top: 0;
    bottom: unset;
    display: flex;
    margin: 4px 0;
  }

  .carousel.carousel-slider .control-arrow {
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    height: 50px;
    margin: auto;
  }

  .box-shadow {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 30px;
    z-index: 1;
    background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  }

  @keyframes mymove {
    from {
      width: 0px;
    }
    to {
      width: 100%;
    }
  }
`;

export const $CarouselIndicator = styled.li`
  position: relative;
  list-style: none;
  flex: 1;
  height: 2px;
  margin: 2px;
  background-color: rgba(0, 0, 0, 0.35);

  ${({ isSelected }) =>
    isSelected &&
    css`
      &::after {
        content: '';
        position: absolute;
        left: 0px;
        height: 2px;
        width: 100%;
        background-color: #fff;
        animation: mymove 5s linear;
      }
    `}

  ${({ isSeen }) =>
    isSeen &&
    css`
      &::after {
        content: '';
        position: absolute;
        left: 0px;
        height: 2px;
        width: 100%;
        background-color: #fff;
      }
    `}
`;
