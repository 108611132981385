import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import axios from 'axios';
import { customFrontendId } from '../utils';
import customVendorFlags from '../utils/customVendorFlags';
import { Modal } from '../css/styles/common';
import '../css/app.scss';
import BillView from './billView';
import { config, pizzaHutSurvey, tacoBellSurvey } from '../config';
import { LoaderContainer, LoaderItem, LoaderText } from '../css/styles';
import { SuspenseLoading } from './SuspenseLoading';
import { campaignTypes } from './campaigns/constants';
import assetUrlReplacer from '../utils/assetUrlReplacer';
import { dummyData } from './billView/data';
import { BrokenLink } from './billView/BrokenLink';
import OTPView from './OTPView';
import RzpLoader from '../components/RzpLoader';
import RzpLogo from '../assets/images/rzp-loader.svg';
import KayaLoader from './KayaLoader';
import Auth from './Auth';

import '@fontsource/inter';
import '@fontsource/inter/700.css';
import RzpBillLoader from './RzpBillLoader';
const ManyavarLoader = lazy(() => import('./ManyavarLoader'));
const WowMomosLoader = lazy(() => import('./WowMomosLoader'));
const WowFranchiseLoader = lazy(() => import('./loaders/WowFranchiseLoader'));
const HouseofFettLoader = lazy(() => import('./HouseofFettLoader'));
const SugarCosmeticsLoader = lazy(() => import('./SugarCosmeticsLoader'));
const SammmLoader = lazy(() => import('./SammmLoader'));
const HimalayaWellnessLoader = lazy(() => import('./HimalayaWellnessLoader'));
// const GoColorsLoader = lazy(() => import('./GoColorsLoader'));

axios.defaults.withCredentials = true;

const App = () => {
  const [showBillLoad, setShowBillLoad] = useState(true);
  const [billState, setBillState]: [any, any] = useState({
    billLoading: true,
    brandName: '',
    brandLogo: '',
    brandId: '',
    companyId: '',
    billImageUrl: '',
    billHtmlUrl: '',
    storeExit: false,
    surveyId: '',
    advertUrl: '',
    adRedirectUrl: '',
    isFeedbackFormOpen: false,
    feedbackSettings: null,
    feedbackExist: false,
    feedbackRating: 0,
    storeId: '',
    vendorId: '',
    shortId: '',
    billUid: '',
    user: '',
    html: '',
    iframeHeight: 0,
    pizzaHutEmail: '',
    isBillOpen: false,
    emptyEmailError: false,
    invalidEmailError: false,
    createdAt: null,
    campaignDetails: [],
    afterSurvey: {},
    campaignDetailsPOB: [],
    coupons: {},
    isEcomBill: false,
    visits: 0,
    parsedData: {},
    customFrontendId: null,
    displayAddress: '',
    storeAddress: '',
    createdDate: new Date().toISOString(),
    surveyData: {},
    deletedBill: false,
    consent: {
      isPresent: 0,
      message: 0,
    },
    isValidBill: false,
    isRzpPos: false,
    isManyavar: false,
    isKaya: false,
    isWowMomos: false,
    isHouseofFett: false,
    isGoColors: false,
    isSugarCosmetics: false,
    isSammm: false,
    isHimalayaWellness: false,
    reviewLink: '',
    reviewRedirectThreshold: 4,
    other: {},
    rewardPoints: {},
  });

  const getShortId = () => {
    if (window.location.pathname === '/') {
      let search = window.location.search.substring(1);
      return (
        search
          .split('&')
          .filter((query) => query.indexOf('i=') > -1 || query.indexOf('getbill=') > -1)
          .map((query) => query.split('=')[1])[0] || ''
      );
    }
    return window.location.pathname.split('/')[1] || '';
  };

  const getForDeletedQuery = () => {
    let search = window.location.search.substring(1);
    return search
      .split('&')
      .filter((query) => query.indexOf('deleted') > -1)
      .map((query) => query.split('=')[1])[0] === 'true'
      ? true
      : false;
  };

  const debounceBillLoad = () => {
    setTimeout(() => setShowBillLoad(false), 3000);
  };

  const fetchBillDetails = React.useCallback(async () => {
    setBillState({ ...billState, billLoading: true });
    const shortId = getShortId();

    if (
      !shortId ||
      shortId === '.env' ||
      shortId === 'favicon.ico' ||
      shortId === 'readReceipt/' ||
      shortId === 'readReceipt'
    ) {
      setBillState({ ...billState, billLoading: false });
      return;
    }

    const forDeletedBill = getForDeletedQuery();

    const queryObj = new URLSearchParams(window.location.search);
    const signed = queryObj.get('signed');
    const queryParams = {
      signed,
    };
    if (forDeletedBill) {
      queryParams['deleted'] = forDeletedBill;
    }
    axios
      .get(`${config.host}/bill/${shortId}`, {
        params: queryParams,
      })
      .then((res) => {
        setTimeout(() => {
          if (res?.data?.code) {
            let stateParams = {};
            if (res?.data?.code === 2) {
              stateParams = {
                otp: res.data?.otp,
                brandLogo: res.data?.storeData?.brand?.brandLogo,
                isValidBill: true,
              };
            } else {
              const brandId = res.data?.bill?.brand?.brandId;
              const storeId = res.data?.bill?.storeId;
              const companyId = res.data?.bill?.companyId;
              const customId = res.data?.bill?.other?.customFrontendId;
              const parsedData = res.data?.bill?.parsedData;
              const isEcomBill = res.data?.bill?.isEcomBill;
              const vendorFlags = customVendorFlags({
                brandId,
                storeId,
                companyId,
                customId,
                parsedData,
                isEcomBill,
              });

              stateParams = {
                brandLogo: assetUrlReplacer(res.data?.bill?.brand?.brandLogo),
                brandName: res.data?.bill?.brand?.brandName,
                brandId: res.data?.bill?.brand?.brandId,
                companyId: res.data?.bill?.companyId,
                vendorId: res.data?.bill?.vendorId,
                billImageUrl: res.data?.bill?.billImageUrl,
                surveyId: res.data?.parsedData?.surveyId || '04-110220161842',
                advertUrl: res.data?.bill?.advertUrl,
                adRedirectUrl: res.data?.bill?.adRedirectUrl,
                storeId: res.data?.bill?.storeId,
                shortId,
                billUid: res.data?.bill?.billUid,
                user: res.data?.bill?.user,
                billHtmlUrl: res.data?.bill?.billHtmlUrl,
                feedbackSettings: { ...res.data?.bill?.feedbackMeta },
                feedbackExist: res.data?.bill?.feedbackExist,
                parsedData: res.data?.bill?.parsedData,
                ...(res.data?.bill?.feedbackRating
                  ? { feedbackRating: res.data.bill.feedbackRating }
                  : {}),
                customFrontendId: res.data?.bill?.other?.customFrontendId,
                displayAddress:
                  res.data?.bill?.parsedData?.storeData?.displayAddress ||
                  res.data?.bill?.other?.displayAddress,
                ...(res.data?.bill?.other?.storeExit
                  ? { storeExit: res.data?.bill?.other?.storeExit }
                  : {}),
                storeAddress:
                  res.data?.bill?.parsedData?.storeData?.storeAddress ||
                  res.data?.bill?.storeAddress,
                other: res.data?.bill?.other,
                createdDate: res.data?.bill?.createdDate,
                coupons: res.data?.bill?.coupons || {},
                surveyData: res.data?.bill?.surveyData,
                deletedBill: forDeletedBill,
                isEcomBill: res.data?.bill?.isEcomBill,
                afterSurvey: res.data?.bill?.afterSurvey,
                visits: res.data?.bill.visits,
                isValidBill: true,
                reviewLink: res.data?.bill?.other?.reviewLink || '',
                isRzpPos: vendorFlags?.isRzpPos && !vendorFlags?.isRzpFtx25,
                isKaya: vendorFlags?.isKaya,
                isManyavar: vendorFlags?.isManyavar,
                isWowMomos: vendorFlags?.isWowMomos,
                isWowChicken: vendorFlags?.isWowChicken,
                isWowKulfi: vendorFlags?.isWowKulfi,
                isWowChina: vendorFlags?.isWowChina,
                isWowFranchise: vendorFlags?.isWowFranchise,
                isHouseofFett: vendorFlags?.isHouseofFett,
                isGoColors: vendorFlags?.isGoColors,
                isSugarCosmetics: vendorFlags?.isSugarCosmetics,
                isSammm: vendorFlags?.isSammm,
                isHimalayaWellness: vendorFlags?.isHimalayaWellness,
                reviewRedirectThreshold: res.data?.bill?.reviewRedirectThreshold || 4,
              };
            }

            setBillState((billState) => ({
              ...billState,
              ...stateParams,
            }));
            if (res.data?.bill?.billHtmlUrl) {
              axios.get(res.data.bill.billHtmlUrl).then((s3res) => {
                if (s3res.data) {
                  setBillState((billState) => ({ ...billState, html: s3res.data }));
                }
              });
            }

            // if (!res.data?.bill.visits) {
            //   axios
            //     .post(`${config.host}/readReceipt/${shortId}`, {})
            //     .then()
            //     .catch(e => console.error(e))
            // }

            const campaignIds = res.data?.bill?.campaignIds;

            // const campaignDetails = dummyData
            //   ?.map(campaign => ({
            //     ...campaign?.content?.body,
            //     campaignId: campaign?._id,
            //     type: campaign?.type
            //   }))
            // setBillState({ campaignDetails })

            // const campaignDetailsPOB = dummyData
            //   ?.filter(campaign => campaign.type === campaignTypes.popupOverBill)

            // setBillState({ campaignDetailsPOB: { ...campaignDetailsPOB[0]?.content } })

            if (campaignIds?.length > 0) {
              axios
                .post(`${config.host}/bills/campaign-details`, {
                  campaignIds,
                  storeId: res.data?.bill?.storeId,
                })
                .then((response) => {
                  const campaignDetails = response?.data?.data?.data?.campaignDetails?.map(
                    (campaign) => ({
                      ...campaign?.content?.body,
                      campaignId: campaign?._id,
                      type: campaign?.type,
                    }),
                  );

                  const campaignDetailsPOB = response?.data?.data?.data?.campaignDetails?.filter(
                    (campaign) => campaign.type === campaignTypes.popupOverBill,
                  );

                  setBillState((billState) => ({
                    ...billState,
                    campaignDetails,
                    campaignDetailsPOB: { ...campaignDetailsPOB[0]?.content },
                  }));
                })
                .catch((err) => console.error(err));
            } else if (!res.data.otp && !campaignIds?.length) {
              axios
                .post(`${config.host}/bills/campaign-details`, {
                  billUid: res.data?.bill?.billUid,
                  storeId: res.data?.bill?.storeId,
                })
                .then((response) => {
                  if (response.status === 204) return;
                  const campaignDetails = response?.data?.data?.data?.campaignDetails?.map(
                    (campaign) => ({
                      ...campaign?.content?.body,
                      campaignId: campaign?._id,
                      type: campaign?.type,
                    }),
                  );

                  const campaignDetailsPOB = response?.data?.data?.data?.campaignDetails?.filter(
                    (campaign) => campaign.type === campaignTypes.popupOverBill,
                  );

                  setBillState((billState) => ({
                    ...billState,
                    coupons: response?.data?.data?.data?.coupons || {},
                    afterSurvey: response?.data?.data?.data?.afterSurvey,
                    surveyData: response?.data?.data?.data?.surveyData,
                    campaignDetails,
                    campaignDetailsPOB: { ...campaignDetailsPOB[0]?.content },
                  }));
                })
                .catch((err) => console.log('Error in campaign details', err));
            }
          } else if (!res?.data?.code) {
            setBillState((billState) => ({
              ...billState,
              shortId,
            }));
          }
          setBillState((billState) => ({
            ...billState,
            billLoading: false,
          }));
        }, 1000);
      })
      .catch((err) => {
        console.log('err', err);
        setBillState((billState) => ({
          ...billState,
          billLoading: false,
          unauthenticated: err?.response?.status === 403 ? true : false,
        }));
      });
  }, []);

  useEffect(() => {
    fetchBillDetails();
  }, [fetchBillDetails]);

  useEffect(() => {
    if (billState.vendorId) {
      axios
        .get(`${config.host}/customer/consent`, {
          params: { vendorId: billState.vendorId, user: billState.user },
        })
        .then((res) => {
          setBillState((billState) => ({
            ...billState,
            consent: {
              isPresent: res?.data?.code,
              message: res?.data?.data?.consentMessage,
            },
          }));
        })
        .catch((e) => console.error(e));
      if (['jqo1um5c99tyn', 'vq7nvum28q2r9i'].includes(billState.companyId)) {
        const email = billState.user.includes('@') ? billState.user : undefined;
        const contact = billState.user.includes('@') ? undefined : billState.user;
        axios
          .post(`${config.host}/auto-engagement/rewards`, {
            vendorId: billState.vendorId,
            contact,
            email,
          })
          .then((res) => {
            setBillState((billState) => {
              return {
                ...billState,
                rewardPoints: {
                  redeemablePoints: res?.data?.data?.redeemable_points,
                  promisedPoints: res?.data?.data?.promised_points,
                  tier: res?.data?.data?.tier,
                },
              };
            });
          })
          .catch((e) => {
            if (e.response.status === 422) {
              setBillState((billState) => {
                return {
                  ...billState,
                  rewardPoints: {
                    notSignedUp: true,
                  },
                };
              });
            }
          });
      }
    }
  }, [billState.vendorId]);

  const handleRatingClick = (rating?: number) => {
    if (billState.reviewLink && Number(rating) >= Number(billState.reviewRedirectThreshold)) {
      axios
        .post(
          `${config?.host}/feedback?billUid=${billState?.billUid}&user=${billState?.user}&storeId=${billState?.storeId}`,
          {
            feedback: '',
            rating,
            type: billState?.feedbackSettings?.type || 'generic',
            campaignId: billState?.feedbackSettings?.campaignId,
          },
        )
        .then((res) => {
          if (res.data.code) {
            setBillState((billState: any) => ({
              ...billState,
              feedbackRating: rating,
              feedbackExist: true,
            }));
          }
        })
        .catch((err) => {
          console.log('err', err);
        });

      window.open(billState.reviewLink);
    } else {
      setBillState((billState: any) => ({
        ...billState,
        isFeedbackFormOpen: true,
        feedbackRating: rating,
      }));
    }
  };

  const handleRatingChange = (rating) => {
    setBillState((billState) => ({
      ...billState,
      feedbackRating: rating,
    }));
  };

  const handleCancelFeedback = (params) => {
    setBillState((billState) => ({
      ...billState,
      isFeedbackFormOpen: false,
      feedbackExist: params?.feedbackSuccess || false,
    }));
  };

  // const handleBillView = () => {
  //   setBillState((billState) => ({
  //     ...billState,
  //     isBillOpen: !billState.isBillOpen
  //   }))
  // }

  // const handleEmail = ({ target: { value } }) => {
  //   setBillState((billState) => ({
  //     ...billState,
  //     pizzaHutEmail: value
  //   }))
  // }

  // const onEmailSubmit = (e) => {
  //   e.preventDefault()

  //   if (isEmpty(billState.pizzaHutEmail)) {
  //     setBillState((billState) => ({
  //       ...billState,
  //       emptyEmailError: true
  //     }))
  //     return
  //   } else if (billState.emptyEmailError === true) {
  //     setBillState((billState) => ({
  //       ...billState,
  //       emptyEmailError: false
  //     }))
  //   }

  //   if (!isEmail(billState.pizzaHutEmail)) {
  //     setBillState((billState) => ({
  //       ...billState,
  //       invalidEmailError: true
  //     }))
  //     return
  //   } else if (billState.invalidEmailError === true) {
  //     setBillState((billState) => ({
  //       ...billState,
  //       invalidEmailError: false
  //     }))
  //   }

  //   const obj = {
  //     email: billState.pizzaHutEmail,
  //     billUid: billState.billUid,
  //     brandName: billState.brandName
  //   }

  //   axios
  //     .post(`${config.host}/emailService`, obj)
  //     .then(res => alert("Email sent successfully"))
  //     .catch(err => console.error("Error::", err))
  // }

  if (billState.billLoading) {
    return (
      <>
        <LoaderContainer>
          <RzpBillLoader />
          <LoaderText>Your bill is on the way...</LoaderText>
        </LoaderContainer>
      </>
    );
  }
  if (billState.unauthenticated)
    return <Auth billUid={getShortId()} fetchBillDetails={fetchBillDetails} />;

  if (!billState.isValidBill) return <BrokenLink />;

  if (billState.otp) return <OTPView {...billState} onViewBillClick={fetchBillDetails} />;
  if (billState.isRzpPos || billState.isKaya) {
    if (billState.billImageUrl || billState.isEcomBill) {
      debounceBillLoad();
    }
  }
  if (billState.customFrontendId === customFrontendId.manyavar && showBillLoad)
    return (
      <Suspense fallback="">
        <ManyavarLoader
          onTransitionEnd={() => {
            setShowBillLoad(false);
          }}
        />
      </Suspense>
    );
  // if (billState.customFrontendId === customFrontendId.goColors && showBillLoad)
  //   return (
  //     <Suspense fallback="">
  //       <GoColorsLoader
  //         onTransitionEnd={() => {
  //           setShowBillLoad(false);
  //         }}
  //       />
  //     </Suspense>
  //   );
  if (billState.isHouseofFett && showBillLoad)
    return (
      <Suspense fallback="">
        <HouseofFettLoader
          onTransitionEnd={() => {
            setShowBillLoad(false);
          }}
        />
      </Suspense>
    );
  if (billState.isSugarCosmetics && showBillLoad)
    return (
      <Suspense fallback="">
        <SugarCosmeticsLoader
          onTransitionEnd={() => {
            setShowBillLoad(false);
          }}
        />
      </Suspense>
    );
  if (billState.isSammm && showBillLoad)
    return (
      <Suspense fallback="">
        <SammmLoader
          onTransitionEnd={() => {
            setShowBillLoad(false);
          }}
        />
      </Suspense>
    );
  if (billState.isWowMomos && showBillLoad)
    return (
      <Suspense fallback="">
        <WowMomosLoader
          onTransitionEnd={() => {
            setShowBillLoad(false);
          }}
        />
      </Suspense>
    );
  if (billState.isHimalayaWellness && showBillLoad)
    return (
      <Suspense fallback="">
        <HimalayaWellnessLoader
          onTransitionEnd={() => {
            setShowBillLoad(false);
          }}
        />
      </Suspense>
    );
  if (billState.isWowFranchise && showBillLoad)
    return (
      <Suspense fallback="">
        <WowFranchiseLoader
          onTransitionEnd={() => {
            setShowBillLoad(false);
          }}
          brand={
            billState.isWowChicken ? 'wowChicken' : billState.isWowKulfi ? 'wowKulfi' : 'wowChina'
          }
        />
      </Suspense>
    );
  return (
    <div id="app-root">
      {/* {billState.billUid && */}
      {showBillLoad && billState.isRzpPos && <RzpLoader />}
      {showBillLoad && billState.isKaya && <KayaLoader />}
      {(billState.billImageUrl || billState.isEcomBill) && !showBillLoad && (
        <BillView
          isFeedbackFormOpen={billState.isFeedbackFormOpen}
          feedbackSettings={billState.feedbackSettings}
          feedbackRating={billState.feedbackRating}
          storeId={billState.storeId}
          brandLogo={billState.brandLogo}
          brandName={billState.brandName}
          brandId={billState.brandId}
          shortId={billState.shortId}
          user={billState.user}
          billUid={billState.billUid}
          feedbackExist={billState.feedbackExist}
          billImageUrl={billState.billImageUrl}
          advertUrl={billState.advertUrl}
          adRedirectUrl={billState.adRedirectUrl}
          handleCancelFeedback={handleCancelFeedback}
          handleRatingClick={handleRatingClick}
          parsedData={billState.parsedData}
          onRatingChange={handleRatingChange}
          customId={billState.customFrontendId}
          displayAddress={billState.displayAddress}
          storeAddress={billState.storeAddress}
          createdDate={billState.createdDate}
          other={billState.other}
          campaignDetails={billState.campaignDetails}
          afterSurvey={billState.afterSurvey}
          coupons={billState.coupons}
          surveyData={billState.surveyData}
          companyId={billState.companyId}
          deletedBill={billState.deletedBill}
          isEcomBill={billState.isEcomBill}
          campaignDetailsPOB={billState.campaignDetailsPOB}
          consent={billState.consent}
          storeExit={billState.storeExit}
          reviewLink={billState.reviewLink}
          isRzpPos={billState.isRzpPos}
          isManyavar={billState.isManyavar}
          isWowMomos={billState.isWowMomos}
          isHouseofFett={billState.isHouseofFett}
          isGoColors={billState.isGoColors}
          isSugarCosmetics={billState.isSugarCosmetics}
          isSammm={billState.isSammm}
          isHimalayaWellness={billState.isHimalayaWellness}
          isWowFranchise={billState.isWowFranchise}
          vendorId={billState.vendorId}
          rewardPoints={billState.rewardPoints}
        />
      )}
      {!billState.isRzpPos &&
        !billState.isKaya &&
        !billState.isManyavar &&
        !billState.isWowMomos &&
        !billState.isWowFranchise &&
        // !billState.isGoColors &&
        !billState.isHouseofFett &&
        !billState.isSugarCosmetics &&
        !billState.isSammm &&
        !billState.isHimalayaWellness && (
          <BillView
            isFeedbackFormOpen={billState.isFeedbackFormOpen}
            feedbackSettings={billState.feedbackSettings}
            feedbackRating={billState.feedbackRating}
            storeId={billState.storeId}
            brandLogo={billState.brandLogo}
            brandName={billState.brandName}
            brandId={billState.brandId}
            shortId={billState.shortId}
            user={billState.user}
            billUid={billState.billUid}
            feedbackExist={billState.feedbackExist}
            billImageUrl={billState.billImageUrl}
            advertUrl={billState.advertUrl}
            adRedirectUrl={billState.adRedirectUrl}
            handleCancelFeedback={handleCancelFeedback}
            handleRatingClick={handleRatingClick}
            parsedData={billState.parsedData}
            onRatingChange={handleRatingChange}
            customId={billState.customFrontendId}
            displayAddress={billState.displayAddress}
            storeAddress={billState.storeAddress}
            createdDate={billState.createdDate}
            campaignDetails={billState.campaignDetails}
            afterSurvey={billState.afterSurvey}
            coupons={billState.coupons}
            surveyData={billState.surveyData}
            companyId={billState.companyId}
            other={billState.other}
            deletedBill={billState.deletedBill}
            isEcomBill={billState.isEcomBill}
            campaignDetailsPOB={billState.campaignDetailsPOB}
            consent={billState.consent}
            storeExit={billState.storeExit}
            reviewLink={billState.reviewLink}
            isManyavar={billState.isManyavar}
            isWowMomos={billState.isWowMomos}
            isHouseofFett={billState.isHouseofFett}
            isGoColors={billState.isGoColors}
            isSugarCosmetics={billState.isSugarCosmetics}
            isSammm={billState.isSammm}
            isHimalayaWellness={billState.isHimalayaWellness}
            vendorId={billState.vendorId}
            rewardPoints={billState.rewardPoints}
          />
        )}
    </div>
  );
};

export default App;
