import React from 'react';

import { InstagramIconofWowFranchise } from '../../assets/images/social';
import { $Flexbox } from '../../css/styles';

const wowFranchiseLinks = {
  wowChicken: {
    instagram: 'https://www.instagram.com/wowchicken_by_wowmomo?igsh=MW8xeW5ma3hqdHMybA==',
  },
  wowChina: {
    instagram: 'https://www.instagram.com/wowchinabywowmomos?utm_source=qr&igsh=OTFxanlhZG5vYzBj',
  },
  wowKulfi: {
    instagram: 'https://www.instagram.com/kulfibywow?igsh=MWFwYjlleWN6am1rdg==',
  },
};

export const WowFranciseSocial = ({ template }) => {
  const instagramLink = wowFranchiseLinks?.[template]?.instagram || '';

  return (
    <React.Fragment>
      <$Flexbox
        className="Socialalign"
        justify="space-around"
        padding="16px 0px"
        alignItems="center">
        <a href={instagramLink} target="_blank" rel="noopener noreferrer">
          <InstagramIconofWowFranchise style={{ width: '33px', height: '30px' }} />
        </a>
      </$Flexbox>
    </React.Fragment>
  );
};
